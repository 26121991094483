<template>
  <div class="body">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="top-box">
          <h4 class="title">The Ticket Tote Roadmap</h4>
          <div class="top-right">
            <div class="post-nav">Post Navigation</div>
            <a v-on:click="scrollSpecY" class="roadmap"
              >The Ticket Tote Roadmap</a
            >
          </div>
        </div>
        <div class="image-container">
          <img
            src="https://www.diyobo.com/userfiles/image/ScreenShot20171213at9.34.05PM.png"
            alt="Ticket Tote Roadmap"
            style="height: 275px; width: 500px; margin-bottom: 25px"
          />
          <div class="image-title" style="font-weight: 500">
            The Ticket Tote Roadmap is Online
          </div>
        </div>
        <div class="roadmap-content">
          <div class="content">
            The NEW INCREDEVENT is here ... and if you're a ticket seller, it is
            time to get excited. Wait ... that's not good enough. It is time to
            get excited!!! Yes, 3 exclamation points should show just how jazzed
            we are about the future. We already offer some of the largest range
            of features for event sellers on the market, but we're not stopping
            there. World domination is on our radar, and we want you to help us
            decide how we achieve that lofty goal.
          </div>
          <div class="content">
            That is why our team have launched the Ticket Tote Roadmap on a
            glorious service called Trello. There you will find what we're
            working on, what we will soon be working on, what the future holds,
            and there's a section for feature requests. Another handy dandy
            little feature is the ability to vote for the features you'd like to
            see us implement (perhaps we'll move them up in the timeline of
            updates). Just signup for a FREE Trello account and click vote after
            you've checked out the card with the feature you'd like to see
            created.
          </div>
          <div class="content">
            We're always working on features and improving the site, but
            sometimes what we're working on is top secret so just know that here
            is where you can find the many features that are coming on top of
            what we can't possibly let our competition know about.
          </div>
          <div class="content">
            So, happy voting, and please also report any bugs you find on the
            Bugs card. We will be checking that card regularly.
          </div>
          <div class="content">Happy selling!</div>
          <div class="jay">Jay</div>
          <div class="owner">Owner/Chief Strategist</div>
          <div class="Ticket Tote">INCREDEVENT</div>
          <div class="date">16 June 2018</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.Ticket Tote {
  margin-bottom: 60px;
}
.content {
  margin-bottom: 20px;
  text-align: center;
}
.roadmap-content {
  margin-top: 60px;
  text-align: center;
}
h2 {
  padding-top: 1em;
}
.title {
  font-size: 50px;
  margin: 0 !important;
}
.body {
  -webkit-box-shadow: inset 0 0 50px 40px #171717e0;
  box-shadow: inset 0 0 50px 40px #171717e0;
  background-color: #222222;
  text-align: center;
  height: 100%;
  max-width: 100%;
}
.top-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5em;
}
.top-right {
  display: flex;
  flex-direction: column;
  margin-top: 19px;
  text-align: left;
}
.roadmap {
  cursor: pointer;
  color: #398df5;
}
</style>

<script>
export default {
  name: "blog",
  methods: {
    scrollSpecY() {
      window.scrollTo(0, 120);
    }
  },
  created() {
    this.$store.commit("setTitle", "Ticket Tote Blog");
  }
};
</script>
